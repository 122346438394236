<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="发送人账号" prop="senderUserId">
                <a-input v-model="queryParam.senderUserId" :maxLength="32" placeholder="请输入发送人账号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="接收人账号" prop="targetId">
                <a-input v-model="queryParam.targetId" :maxLength="32" placeholder="请输入接收人账号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="发送人昵称" prop="senderNickName">
                <a-input v-model="queryParam.senderNickName" :maxLength="32" placeholder="请输入发送人昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="接收人昵称" prop="targetNickName">
                <a-input v-model="queryParam.targetNickName" :maxLength="32" placeholder="请输入接收人昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="开始时间" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                               :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="结束时间" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                               :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>{{ $t('通用.按钮.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon
                  type="redo"/>{{ $t('通用.按钮.重置') }}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['customer:noticerecord:add']">
                  <a-icon type="plus" />{{$t('通用.按钮.新增')}}
                </a-button>
                <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['customer:noticerecord:edit']">
                  <a-icon type="edit" />{{$t('通用.按钮.修改')}}
                </a-button>
                <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['customer:noticerecord:remove']">
                  <a-icon type="delete" />{{$t('通用.按钮.删除')}}
                </a-button>-->
        <a-button type="primary" @click="handleExport" v-hasPermi="['customer:noticerecord:export']">
          <a-icon type="download"/>
          {{ $t('通用.按钮.导出') }}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="content" slot-scope="text, record">
          {{ record.content.content }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
             <a-icon type="edi"/>详情
          </a>
          <a-divider type="vertical" v-hasPermi="['customer:noticerecord:remove']"/>
          <!--          <a @click="handleDelete(record)" v-hasPermi="['customer:notice/record:remove']">
                      <a-icon type="delete" />{{$t('通用.按钮.删除')}}
                    </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageNoticeRecord, listNoticeRecord, delNoticeRecord} from '@/api/customer/noticeRecord'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'

export default {
  name: 'NoticeRecord',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        canincludeexpansion: undefined,
        channelid: undefined,
        channeltype: undefined,
        content: undefined,
        conversationtype: undefined,
        directeduserids: undefined,
        disablenotification: undefined,
        ismentioned: undefined,
        ismodifymessage: undefined,
        isofflinemessage: undefined,
        ispersited: undefined,
        isstatusmessage: undefined,
        messagedirection: undefined,
        messageid: undefined,
        messagetype: undefined,
        messageuid: undefined,
        pushconfig: undefined,
        readreceiptinfo: undefined,
        receivedstatus: undefined,
        receivedtime: undefined,
        senderuserid: undefined,
        targetNickName: undefined,
        senderNickName: undefined,
        sentstatus: undefined,
        senttime: undefined,
        targetid: undefined,
        direction: undefined,
        text: undefined,
        offline: undefined,
        remote: undefined,
        thumbnailbase64string: undefined,
        readType: undefined,
        privateType: undefined,
        businessId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: "发送人昵称",
          dataIndex: 'senderNickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: "发送人账号",
          dataIndex: 'senderUserId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: "接收人昵称",
          dataIndex: 'targetNickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: "接收人账号",
          dataIndex: 'targetId',
          ellipsis: true,
          align: 'center'
        },
        /* {
           title: "聊天内容",
           dataIndex: 'content',
           scopedSlots: { customRender: 'content' },
           ellipsis: true,
           align: 'center'
         },*/

        /* {
           title: "消息类型",
           dataIndex: 'messagetype',
           ellipsis: true,
           align: 'center'
         },*/

        {
          title: "插入时间",
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    /** 查询客服聊天记录融云列表 */
    getList() {
      this.loading = true
      pageNoticeRecord(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        canincludeexpansion: undefined,
        channelid: undefined,
        channeltype: undefined,
        content: undefined,
        conversationtype: undefined,
        directeduserids: undefined,
        disablenotification: undefined,
        ismentioned: undefined,
        ismodifymessage: undefined,
        isofflinemessage: undefined,
        ispersited: undefined,
        isstatusmessage: undefined,
        messagedirection: undefined,
        messageid: undefined,
        messagetype: undefined,
        messageuid: undefined,
        pushconfig: undefined,
        readreceiptinfo: undefined,
        receivedstatus: undefined,
        receivedtime: undefined,
        senderuserid: undefined,
        targetNickName: undefined,
        senderNickName: undefined,
        sentstatus: undefined,
        senttime: undefined,
        targetid: undefined,
        direction: undefined,
        text: undefined,
        offline: undefined,
        remote: undefined,
        thumbnailbase64string: undefined,
        readType: undefined,
        privateType: undefined,
        businessId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField === 'createTime') {
          this.queryParam.sortField = 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delNoticeRecord(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleSelectExport() {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk() {
          that.download('customer/notice/record/export', queryParam, `客服聊天记录_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true
          that.selectedRowKeys = []
        },
        onCancel() {
          that.ids = []
          that.multiple = true
          that.selectedRowKeys = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('customer/notice/record/export', {
            ...that.queryParam
          }, `客服聊天记录_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
  }
}
</script>
